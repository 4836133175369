import '../css/style.css';

import { StaticQuery, graphql } from 'gatsby';

import Footer from './footer';
import Header from './header';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

const Layout = ({ children, location, bgOpacity }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        file(relativePath: { regex: "/hero-bg/" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="relative testing">
        <Img
          style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}
          imgStyle={{
            objectFit: `cover`,
            objectPosition: `center bottom`
          }}
          fluid={data.file.childImageSharp.fluid}
        />
        <div
          className="fixed pin"
          style={{
            backgroundImage:
              'linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.5))'
          }}
        />

        <main className="relative flex flex-col min-h-screen text-black">
          <Header
            siteTitle={data.site.siteMetadata.title}
            location={location}
          />

          <div className="flex flex-grow justify-center container mx-auto px-4 py-4 md:px-8">
            {children}
          </div>

          <Footer location={location} />
        </main>
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
